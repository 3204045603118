import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, Switch, useNavigate } from "react-router-dom";
export default function Logout(props) {
    const navigate = useNavigate();
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("profileid");
    sessionStorage.removeItem("name");
    
    useEffect(() => {
        navigate("/login");
        props.change_logout_menu();
      }, [props]);
      
}
